<template>
  <div class="tooltip" @mouseenter="showTooltip" @mouseleave="hideTooltip">
    <span class="tooltiptext" v-show="isTooltipVisible" v-if="active" :class="{ [position]: true }">
      {{ content }}
      <span class="arrow"></span>
    </span>
    <slot @mouseover="showTooltip" @mouseout="hideTooltip"></slot>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  data() {
    return {
      isTooltipVisible: false,
    };
  },
  props: {
    content: { default: "Tooltip text", type: String },
    active: { default: true, type: Boolean },
    position: { default: "right", type: String, validator: isValidPosition },
  },
  methods: {
    showTooltip() {
      this.isTooltipVisible = true;
    },
    hideTooltip() {
      this.isTooltipVisible = false;
    },
  },
  computed: {
    tooltipClasses() {
      return {
        [this.position]: true,
      };
    },
  },
};

function isValidPosition(value) {
  return ["left", "right", "top", "bottom"].includes(value);
}
</script>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltiptext {
  visibility: hidden;
  white-space: pre-line;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  width: max-content;
  max-width: 200px;
}

.tooltip .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.arrow {
  position: absolute;
  top: 50%;
  left: -10px;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;

}

.left {
  left: auto;
  right: calc(100% + 8px);

  & .arrow {
    top: 50%;
    left: 100%;
  }
}

.top {
  top: auto;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);

  & .arrow {
    transform: rotate(90deg);
    top: calc(100% + 5px);
    left: 50%;
  }
}

.bottom {
  bottom: auto;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);

  & .arrow {
    transform: rotate(-90deg);
    top: -5px;
    left: 50%;
  }
}

.right {
  left: calc(100% + 8px);
  right: auto;

  & .arrow {
    top: 50%;
    left: -9px;
    transform: rotate(180deg);
  }
}
</style>

<template>
  <div>
    <div class="flex">
      <div class="flex">
        <p class="font-barlow font-medium text-xl text-grey-medium-2">
          Survey Details /
        </p>
        <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
          Score Settings
        </p>
      </div>
      <div class="flex flex-grow justify-end">
        <Button
          v-if="isReadOnly"
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrowLeft"
          rotateArrow
          @click.native="goBack"
        />
        <Button
          v-if="!isReadOnly"
          text="Cancel"
          type="secondary"
          size="medium"
          data-testid="next-button"
          class="mr-1"
          @click.native="goBack"
        />
        <Button
          v-if="!isReadOnly"
          text="Save"
          type="primary"
          :iconRight="arrowRight"
          size="medium"
          :disabled="disableSave"
          @click.native="save"
        />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <div class="mb-4">
        <p class="font-barlow font-bold text-mdl text-grey-light mb-1">
          Scoring and Sub-competency Mapping
        </p>
        <p class="text-mdh text-grey-dark-1 max-w-3/5">
          Choose the score options for each Competency Level.
        </p>
      </div>
      <div class="ml-2">
        <p class="text-grey-dark-1 text-mdlh font-medium mb-2">
          Insert name levels and scores
        </p>
        <ScoreItem
          v-for="(item, index) in scores"
          :key="item.id"
          :index="index"
          class="ml-3"
          :optionsCount="scores.length"
        />
        <Tooltip v-if="!isReadOnly" content="You can add a maxim number of 10 maturity levels" style="margin-left:386px" :active="scores.length >= 10">
          <Button type="secondary" :iconLeft="addIcon" text="Add Level" @click.native="addScore" :disabled="scores.length >= 10" />
        </Tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import arrowRight from "@/assets/img/icons/arrow-right-white.svg";
import arrowLeft from "@/assets/img/icons/arrow-right.svg";
import addIcon from "@/assets/img/icons/add-blue.svg";
import Button from "../../../components/Button/Button.vue";
import ScoreItem from "../components/ScoreItem.vue";
import Tooltip from "../../../components/Tooltip/Tooltip.vue";

export default {
  name: "ProductScores",
  components: { ScoreItem, Button, Tooltip },
  data: () => ({
    arrowRight,
    arrowLeft,
    addIcon,
  }),
  computed: {
    ...mapState({
      scores: (state) => state.products.scores,
      productDetails: (state) => state.products.productDetails,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    isIndividual() {
      return this.productDetails.surveyType === 0;
    },
    disableSave() {
      const scoreItems = this.scores.map((item) => item.score);
      const hasDuplicates = scoreItems.some((item) => {
        if (scoreItems.indexOf(item) !== scoreItems.lastIndexOf(item))
          return true;
        return false;
      });
      const scoreOverLimit = scoreItems.some(x => x >= scoreItems.length);

      const hasAllScoreNames = this.scores.every(
        (item) => item?.name?.trim() !== ""
      );
      return !hasAllScoreNames || hasDuplicates || scoreOverLimit;
    },
  },
  methods: {
    ...mapActions({
      setScores: "products/setScores",
      updateScoreSettings: "products/updateScoreSettings",
    }),
    goBack() {
      return this.$router.push(
        `/products/${this.productDetails.productId}/dashboard`
      );
    },
    addScore() {
      this.setScores([...this.scores, { score: 0, name: "", id: -this.scores.length }]);
    },
    async save() {
      const id = this.productDetails.id;
      await this.updateScoreSettings({
        id,
        payload: { surveyId: id, scores: this.scores },
      }).then((rsp) => rsp && this.$router.push("questions"));
    },
  },
  mounted() {
    if (!this.scores.length) {
      const count = this.isIndividual ? 5 : 6;
      const scores = [];
      for (let i = 0; i < count; i++) {
        scores.push({ score: 0, name: "", id: (-i).toString() });
      }
      this.setScores(scores);
    }
  },
};
</script>

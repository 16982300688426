<template>
  <div class="flex gap-2 mb-3 items-end">
    <InputField
      :label="`Level Name ${index + 1}`"
      placeholder="e.g: Mastery"
      class="w-350"
      :disabled="isReadOnly"
      :defaultValue="name"
      :maxlength="100"
      :onChange="(value) => (name = value)"
    />
    <Select
      label="Score"
      :disabled="isReadOnly"
      :options="options"
      :value="score"
      :onSelect="(value) => (score = value)"
    />
    <Tooltip :active="this.scores.length <= 1" content="You need at least one maturity level for this type of survey">
      <Button v-if="!isReadOnly" :disabled="this.scores.length <= 1" :iconLeft="deleteIcon" type="secondary" size="medium" text="" :onClick="deleteScore"/>
    </Tooltip>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import InputField from "../../../components/InputField/InputField.vue";
import Button from "../../../components/Button/Button.vue";
import Select from "../../../components/Select/NewSelect.vue";
import Tooltip from "../../../components/Tooltip/Tooltip.vue";
import deleteIcon from "@/assets/img/icons/remove.svg";

export default {
  name: "ScoreItem",
  components: { InputField, Select, Button, Tooltip },
  props: {
    index: { default: 0, type: Number },
    optionsCount: {default: 0, type: Number},
  },
  data: () => ({
    deleteIcon,
    options: [],
  }),
  computed: {
    ...mapState({
      scores: (state) => state.products.scores,
    }),
    ...mapGetters({
      isReadOnly: "products/isReadOnly",
    }),
    name: {
      get() {
        return this.scores[this.index].name;
      },
      set(value) {
        this.setScoreName({ name: value, index: this.index });
      },
    },
    score: {
      get() {
        const value = this.scores[this.index].score;
        return { label: value, id: value };
      },
      set(value) {
        this.setScoreValue({ score: value, index: this.index });
      },
    },
  },
  watch: {
    optionsCount(value) {
      this.options = Array.from({ length: value }, (_, index) => ({ label: index, id: index.toString() }));
    }
  },
  methods: {
    ...mapActions({
      setScoreName: "products/setScoreName",
      setScoreValue: "products/setScoreValue",
      openModal: "modals/openModal",
    }),
    deleteScore() {
      this.openModal({ modal: "deleteScoreModal", props: { index: this.index }, });
    }
  },
  mounted() {
    this.options = Array.from({ length: this.optionsCount }, (_, index) => ({ label: index, id: index.toString() }));
  }
};
</script>
